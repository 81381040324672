import React, { useState, useEffect } from 'react';

import Modal from 'react-modal';
// Modal.setAppElement('#root');

import Navbar from '../inc/Navbar'
// import Slider from '../inc/Slider'
import "./Home.css";
import Products from './Products';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ScrollToTop from "react-scroll-to-top";

import Sareecollection from "../images/SAREE NEW.jpg"
import Newsaree from "../images/Saree Banarasee 2.jpg"
import redsaree from "../images/Saree Maroon.jpg"
import redsareeCOLOR from "../images/Banarasee Saree.jpg"
import Collection from "../images/Saree Red Color.jpg"
import Bannerslider from '../inc/Bannerslider';
import Manmohinifamilystore from "../images/manmohini-allbranch.jpg";
import Manmohinsaree from "../images/saree red.jpg"
import Weeding from '../inc/Weeding';
import ReactPlayer from "react-player";
import Notice from './Notice';


import { Link } from 'react-router-dom';
import shirt1 from "../images/shirt.jpg";
import Panjabi from "../images/panjabi-manmohini.jpg";
import Jeans from "../images/jeans-pant.jpg";
import Trackpants from "../images/TRACK PANT.jpg";
import Trousers from "../images/trousers-mens.jpg";
import EthnicWear from "../images/Ethnic wear.jpg";
import Shirt from "../images/shirt.jpg"
import Tshirt from "../images/t-shirt.jpg";
import blazers from "../images/blazers.jpg";

import Saree from "../images/saree-new.jpg";
import Lehenga from "../images/lehenga.jpg";
import Top from "../images/TOP.jpg";
import Dresses from "../images/dresses.jpg";

import Jeansnew from "../images/jeans.jpg";
import JeanKurtasets from "../images/KURTA SETS.jpg";
import Ethnicwear from "../images/Ethnic Wear Women.jpg";
import Trousersnew from "../images/trousers -womwn.jpg";
import Lengerie from "../images/Lengerie.jpg";
import Winter from './Winter';
import wintercollection from "../images/winter cloth.jpg";


// import blackberrys from "../images/blackberrys-logo.jpg";
// import Loiusphilippe from "../images/LOUIS PHILLEP-LOGO.jpg";
// import VanHeusen from "../images/VANHEUSEN.jpg";
// import muftilogo from "../images/mufti-logo.jpg";
// import Allensolly from "../images/Allen solly.jpg";
// import Parkavenue from "../images/park avenue.jpg";
// import spykar from "../images/spykar.jpg";
// import killer from "../images/killer.jpg";
// import turtle from "../images/turtle.jpg";
// import beinghuman from "../images/beign human.jpg"
// import mantecarlo from "../images/monte carlo.jpg";
// import papejeanslondon from "../images/Papejeands-london.jpg";
// import sweetdream from "../images/SWEET DREAM.jpg";

// import uptobrand from "../images/allbrand.jpg";



// import { DefaultPlayer as Video } from 'react-html5video';
// import 'react-html5video/dist/styles.css';
// import pijooffer from "../images/manmohini_pujo_offer.mp4";



function Home() {


    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setModalOpen(true);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    const closeModal = () => setModalOpen(false);







    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };


    return (
        <div>
            <Navbar />

            <br />
            <br />
            <br />

            <ScrollToTop smooth top="100" color="grey" />
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <h4>Manmohini Berhampore</h4>
                    </div>
                </div>
            </div>


            <Bannerslider />
            <div className='manmohiniberhampore1'>
                <div className="container">

                    <div className="row">

                        <div className="col-md-8">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="notice">

                                    <div style={{ color: "darkBlue" }} className="card-header blink"><center><b>Notice</b></center></div>

                                </div>

                                <div className="col-md-12 mt-2">
                                    <Notice />
                                    {/* <marquee>
                    <h5 style={{ color: "white" }} > Everyday Shop Open | Manmohin Bolpur is closed on Wednesday</h5>
                  </marquee> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Products />
            <br />
            <br />
            <div className='freeship'>
                <div className="container">

                    <div className="row">
                        <h5 className='mb-0 mt-3'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-truck" viewBox="0 0 16 16">
                            <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5zm1.294 7.456A2 2 0 0 1 4.732 11h5.536a2 2 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456M12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
                        </svg> FREE SHIPPING ALL OVER INDIA</h5>
                        <p style={{ color: "grey" }}> <small>Above Rs. 2,000/-</small></p>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-1">
                <div className="container">
                    <div className="row">
                        <div className="videreactjs">
                            <ReactPlayer width="100%" height="100%" url="https://www.youtube.com/watch?v=ikMpYB_Wxog" />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <Winter />
            <div className="album py-2">

                <div className="container">

                    <div className="row">
                        <h2 style={{ color: "maroon" }}>Features Collection </h2>
                    </div>
                </div>
            </div>


            <div className='App'>

                <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
                    <div className="album py-2">
                        <div className="container">
                            <div className="row">
                                <br />
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <center>
                        <h2 style={{ color: "maroon" }}>শীতকালে আপনার ছোট্ট সোনাকে মনের মতো করে সাজাতে চলে আসুন মনমোহিনী- তে। </h2>
                    </center>
                    <center>
                        <div className="album py-0">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div class="card">
                                            <img src={wintercollection} className="mb-0 mt-2" alt="wintercollection" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </center>
                    <br />
                   



                    {/* <div className="album py-2">
                        <div className="container">
                            <div className="row">

                                <Carousel responsive={responsive}>
                                    <div className="card mb-3 me-3" >
                                        <img className="card-img-top" src={muftilogo} alt="blackberrys" />

                                    </div>
                                    <div className="card mb-3 me-3" >
                                        <img className="card-img-top" src={Loiusphilippe} alt="Loiusphilippe" />

                                    </div>
                                    <div className="card mb-3 me-3" >
                                        <img className="card-img-top" src={blackberrys} alt="muftilogo" />

                                    </div>
                                    <div className="card mb-3 me-3" >
                                        <img className="card-img-top" src={VanHeusen} alt="blackberrys" />

                                    </div>

                                    <div className="card mb-3 me-3" >
                                        <img className="card-img-top" src={Allensolly} alt="Allensolly" />

                                    </div>
                                    <div className="card mb-3 me-3" >
                                        <img className="card-img-top" src={Parkavenue} alt="Parkavenue" />

                                    </div>
                                    <div className="card mb-3 me-3" >
                                        <img className="card-img-top" src={spykar} alt="spykar" />

                                    </div>

                                    <div className="card mb-3 me-3" >
                                        <img className="card-img-top" src={killer} alt="killer" />
                                    </div>

                                    <div className="card mb-3 me-3" >
                                        <img className="card-img-top" src={turtle} alt="turtle" />
                                    </div>

                                    <div className="card mb-3 me-3" >
                                        <img className="card-img-top" src={beinghuman} alt="beinghuman" />
                                    </div>

                                    <div className="card mb-3 me-3" >
                                        <img className="card-img-top" src={mantecarlo} alt="mantecarlo" />
                                    </div>
                                    <div className="card mb-3 me-3" >
                                        <img className="card-img-top" src={papejeanslondon} alt="papejeanslondon" />
                                    </div>
                                    <div className="card mb-3 me-3" >
                                        <img className="card-img-top" src={sweetdream} alt="sweetdream" />
                                    </div>
                                </Carousel>
                            </div>
                        </div>

                    </div> */}
                    <div className="d-flex justify-content-end align-items-center mb-3">
                        <button className='btn btn-secondary btn-sm mt-2' onClick={closeModal}>Close</button>
                    </div>
                </Modal>
            </div>


            <div className="album py-0">
                <div className="container">
                    <div className="row">

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='saree'>
                                        <div className="card shadow">
                                            <img src={Saree} className="card-img-top " alt="saree" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 style={{ color: "darkblue" }} className="card-title">Saree</h5>
                                                    <a href="saree" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>


                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='top-teas'>
                                        <div className="card shadow">
                                            <img src={Top} className="card-img-top" alt="top" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title">Top & Teas</h5>
                                                    <a href="top-teas" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Lehenga'>
                                        <div className="card shadow">
                                            <img src={Lehenga} className="card-img-top" alt="lehenga" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title">Lehenga</h5>
                                                    <a href="Lehenga" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='dresses'>
                                        <div className="card shadow">
                                            <img src={Dresses} className="card-img-top" alt="dresses" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title">Dresses</h5>
                                                    <a href="dresses" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="album py-0">
                <div className="container">
                    <div className="row">

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Jeans-Women'>
                                        <div className="card shadow">
                                            <img src={Jeansnew} className="card-img-top" alt="Jeans" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title">Jeans</h5>
                                                    <a href="Jeans-Women" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>


                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Kurtaset'>
                                        <div className="card shadow">
                                            <img src={JeanKurtasets} className="card-img-top" alt="JeanKurtasets" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title">Kurta Sets</h5>
                                                    <a href="Kurtaset" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Women-Ethnic-wear'>
                                        <div className="card shadow">
                                            <img src={Ethnicwear} className="card-img-top" alt="Ethnicwear" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title">Ethnic Wear</h5>
                                                    <a href="Women-Ethnic-wear" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Women-Trousers'>
                                        <div className="card shadow">
                                            <img src={Trousersnew} className="card-img-top" alt="Trousers" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title">Trousers</h5>
                                                    <a href="Women-Trousers" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>



            <div className='album py-2'>
                <div className="container">

                    <div className="row">



                        <div className="col-md-3 mt-3">
                            <a href='Lingeria'>
                                <div className="card shadow" >
                                    <img src={Lengerie} className="card-img-top" alt="Lengerie" />
                                    <div className="card-body">
                                        <center>
                                            <h5 className="card-title">Lingerie</h5>
                                            <a href="Lingeria" className="appbuttonmanmohinicall">Learn More</a>
                                        </center>
                                    </div>
                                </div>
                            </a>
                        </div>


                    </div>
                </div>
            </div>
            <br />
            <br />




            <div className="album py-3">
                <div className="container">
                    <div className="row">

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='shirts'>
                                        <div className="card">
                                            <img src={shirt1} className="card-img-top" alt="Locketnew" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 style={{ color: "darkblue" }} className="card-title mb-1">Shirt</h5>
                                                    <p style={{ color: "grey" }} className="card-text ">Description content...</p>
                                                    <a href="shirts" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>


                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='mens-tshirt'>
                                        <div className="card">
                                            <img src={Tshirt} className="card-img-top" alt="Hansh" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title mb-1">T-Shirt</h5>
                                                    <p style={{ color: "grey" }} className="card-text">Description content....</p>
                                                    <a href="mens-tshirt" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Blazer'>
                                        <div className="card">
                                            <img src={blazers} className="card-img-top" alt="Butterfly" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title mb-1">Blazers</h5>
                                                    <p style={{ color: "grey" }} className="card-text">Description content....</p>
                                                    <Link to="/Blazer" className="appbuttonmanmohinicall">Learn More</Link>
                                                </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Punjabi'>
                                        <div className="card">
                                            <img src={Panjabi} className="card-img-top" alt="punjabi" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title mb-1">Punjabi</h5>
                                                    <p style={{ color: "grey" }} className="card-text">Description content...</p>
                                                    <a href="Punjabi" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="album py-0">
                <div className="container">
                    <div className="row">

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Jeans'>
                                        <div className="card">
                                            <img src={Jeans} className="card-img-top" alt="Locketnew" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title mb-1">Jeans</h5>
                                                    <p style={{ color: "grey" }} className="card-text">Description content....</p>
                                                    <a href="Jeans" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>


                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='track-pants'>
                                        <div className="card">
                                            <img src={Trackpants} className="card-img-top" alt="trackpant" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title mb-1">Track Pants</h5>
                                                    <p style={{ color: "grey" }} className="card-text">Description content....</p>
                                                    <a href="track-pants" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='trousers'>
                                        <div className="card">
                                            <img src={Trousers} className="card-img-top" alt="Trousers" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title mb-1">Trousers</h5>
                                                    <p style={{ color: "grey" }} className="card-text">Description content....</p>
                                                    <a href="trousers" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Ethnic-Wear'>
                                        <div className="card">
                                            <img src={EthnicWear} className="card-img-top" alt="EthnicWear" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title mb-1">Ethnic Wear</h5>
                                                    <p style={{ color: "grey" }} className="card-text">Description content....</p>
                                                    <a href="Ethnic-Wear" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <br />

            <div className="album py-1">
                <div className="container">
                    <div className="row">

                        <Carousel responsive={responsive}>
                            <div className="card border mb-3 me-3" >
                                <img className="card-img-top" src={Sareecollection} alt="Manmohini" />
                                <div className="card-body text">
                                    <center>
                                        <h6 className="card-title mb-0"><b>Saree </b></h6>
                                        <p className="card-text"><small>Price </small></p>
                                    </center>
                                </div>
                            </div>
                            <div className="card border mb-3 me-3" >
                                <img className="card-img-top" src={Newsaree} alt="Manmohini" />
                                <div className="card-body text">
                                    <center>
                                        <h6 className="card-title mb-0"><b>Saree  </b></h6>
                                        <p className="card-text"><small>Price:-</small></p>
                                    </center>
                                </div>
                            </div>
                            <div className="card border mb-3 me-3" >
                                <img className="card-img-top" src={redsaree} alt="Manmohini" />
                                <div className="card-body text">
                                    <center>
                                        <h6 className="card-title mb-0"><b>Saree </b></h6>
                                        <p className="card-text"><small>Price</small></p>
                                    </center>
                                </div>
                            </div>
                            <div className="card border mb-3 me-3" >
                                <img className="card-img-top" src={redsareeCOLOR} alt="Manmohini" />
                                <div className="card-body text">
                                    <center>
                                        <h6 className="card-title mb-0"><b>Saree </b></h6>
                                        <p className="card-text"><small>Price</small></p>
                                    </center>
                                </div>
                            </div>



                            <div className="card border mb-3 me-3" >
                                <img className="card-img-top" src={Collection} alt="Manmohini" />
                                <div className="card-body text">
                                    <center>
                                        <h6 className="card-title mb-0"><b>Saree  </b></h6>
                                        <p className="card-text"><small>Price</small></p>
                                    </center>
                                </div>
                            </div>
                        </Carousel>
                    </div>
                </div>

            </div>

            <br />

            <br />
            <a href='womens'>
                <div className='bannerradius'>
                    <img src={Manmohinsaree} className="d-block w-100" alt="manmohini" />
                </div>
            </a>
            <br />
            <div className='album py-1'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mt-3">

                            <div className="card-body">
                                <h4 className="card-title">Weeding Collection</h4>
                                <p className="card-text">Best Weeding Collection at MANMOHINI</p>
                                <a href="#" className="whatsappbook">Explore</a>

                            </div>
                        </div>
                        <div className="col-md-6 mt-3">

                            <Weeding />

                        </div>
                    </div>
                </div>
            </div>
            <br />


            <br />
        </div>
    )
}

export default Home